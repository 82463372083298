import { FC } from 'react';
import styled, { keyframes } from 'styled-components';
import SvgIconWrapper from './SvgIconWrapper';
import queries from './container-queries.module.css';
import { ReactComponent as AddToList } from './svgs/add-to-list.svg';
import { ReactComponent as AdminShield } from './svgs/admin-shield.svg';
import { ReactComponent as AnalyzeAlt } from './svgs/analyze-alt.svg';
import { ReactComponent as AnalyzeMenu } from './svgs/analyze-menu.svg';
import { ReactComponent as Analyze } from './svgs/analyze.svg';
import { ReactComponent as ArrowDownRight } from './svgs/arrow-down-right.svg';
import { ReactComponent as ArrowDown } from './svgs/arrow-down.svg';
import { ReactComponent as ArrowRight } from './svgs/arrow-right.svg';
import { ReactComponent as ArrowUpRight } from './svgs/arrow-up-right.svg';
import { ReactComponent as ArrowUp } from './svgs/arrow-up.svg';
import { ReactComponent as Astromech } from './svgs/astromech.svg';
import { ReactComponent as Benchmark } from './svgs/benchmark.svg';
import { ReactComponent as Boolean } from './svgs/boolean.svg';
import { ReactComponent as Code } from './svgs/browser-embed.svg';
import { ReactComponent as Calendar } from './svgs/calendar.svg';
import { ReactComponent as Chat } from './svgs/chat.svg';
import { ReactComponent as Check } from './svgs/check.svg';
import { ReactComponent as ChevronDown } from './svgs/chevron-down.svg';
import { ReactComponent as ChevronLeft } from './svgs/chevron-left.svg';
import { ReactComponent as ChevronRight } from './svgs/chevron-right.svg';
import { ReactComponent as ChevronUp } from './svgs/chevron-up.svg';
import { ReactComponent as CircleCheck } from './svgs/circle-check.svg';
import { ReactComponent as CircleClock } from './svgs/circle-clock.svg';
import { ReactComponent as CircleCross } from './svgs/circle-cross.svg';
import { ReactComponent as CircleError } from './svgs/circle-error.svg';
import { ReactComponent as CircleInfoFilled } from './svgs/circle-info-filled.svg';
import { ReactComponent as CircleInfo } from './svgs/circle-info.svg';
import { ReactComponent as CirclePlus } from './svgs/circle-plus.svg';
import { ReactComponent as CircleQuestion } from './svgs/circle-question.svg';
import { ReactComponent as CircleSlash } from './svgs/circle-slash.svg';
import { ReactComponent as CircleUser } from './svgs/circle-user.svg';
import { ReactComponent as Close } from './svgs/close.svg';
import { ReactComponent as Collapse } from './svgs/collapse.svg';
import { ReactComponent as Comment } from './svgs/comment.svg';
import { ReactComponent as Computer } from './svgs/computer.svg';
import { ReactComponent as ContactCheck } from './svgs/contact-check.svg';
import { ReactComponent as Contact } from './svgs/contact.svg';
import { ReactComponent as Contacts } from './svgs/contacts.svg';
import { ReactComponent as CreditCard } from './svgs/credit-card.svg';
import { ReactComponent as CrowFancy } from './svgs/crown-fancy.svg';
import { ReactComponent as Crown } from './svgs/crown.svg';
import { ReactComponent as Dashboard } from './svgs/dashboard.svg';
import { ReactComponent as Debug } from './svgs/debug.svg';
import { ReactComponent as DefaultThumbnail } from './svgs/default-thumbnail.svg';
import { ReactComponent as DeleteSchedule } from './svgs/delete-schedule.svg';
import { ReactComponent as Delete } from './svgs/delete.svg';
import { ReactComponent as Diamond } from './svgs/diamond.svg';
import { ReactComponent as Differences } from './svgs/differences.svg';
import { ReactComponent as Download } from './svgs/download.svg';
import { ReactComponent as EasterEgg } from './svgs/easter-egg.svg';
import { ReactComponent as Edit } from './svgs/edit.svg';
import { ReactComponent as EmailSchedule } from './svgs/email-schedule.svg';
import { ReactComponent as Email } from './svgs/email.svg';
import { ReactComponent as Expand } from './svgs/expand.svg';
import { ReactComponent as ExternalLink } from './svgs/external-link.svg';

import { ReactComponent as DollarSign } from './svgs/dollar-sign.svg';
import { ReactComponent as Globe } from './svgs/globe.svg';
import { ReactComponent as HeartFilled } from './svgs/heart-filled.svg';
import { ReactComponent as Heart } from './svgs/heart.svg';
import { ReactComponent as Image } from './svgs/image.svg';
import { ReactComponent as LearningVideo } from './svgs/learning-video.svg';
import { ReactComponent as Lightbulb } from './svgs/lightbulb.svg';
import { ReactComponent as Lighthouse } from './svgs/lighthouse.svg';
import { ReactComponent as Link } from './svgs/link.svg';
import { ReactComponent as List } from './svgs/list.svg';
import { ReactComponent as LockLocked } from './svgs/lock-locked.svg';
import { ReactComponent as LockUnlocked } from './svgs/lock-unlocked.svg';
import { ReactComponent as Match0 } from './svgs/match0.svg';
import { ReactComponent as Match1 } from './svgs/match1.svg';
import { ReactComponent as Match2 } from './svgs/match2.svg';
import { ReactComponent as Match3 } from './svgs/match3.svg';
import { ReactComponent as Menu } from './svgs/menu.svg';
import { ReactComponent as Minus } from './svgs/minus.svg';
import { ReactComponent as MndFilled } from './svgs/mnd-filled.svg';
import { ReactComponent as Monitor } from './svgs/monitor.svg';
import { ReactComponent as Moon } from './svgs/moon.svg';
import { ReactComponent as MoreHorizontal } from './svgs/more-horizontal.svg';
import { ReactComponent as MoreVertical } from './svgs/more-vertical.svg';
import { ReactComponent as Notification } from './svgs/notification.svg';
import { ReactComponent as Paperclip } from './svgs/paperclip.svg';
import { ReactComponent as PasswordUnlock } from './svgs/password-unlock.svg';
import { ReactComponent as Pen } from './svgs/pen.svg';
import { ReactComponent as Pencil } from './svgs/pencil.svg';
import { ReactComponent as Phone } from './svgs/phone.svg';
import { ReactComponent as PinFilled } from './svgs/pin-filled.svg';
import { ReactComponent as Pin } from './svgs/pin.svg';
import { ReactComponent as Play } from './svgs/play.svg';
import { ReactComponent as Plus } from './svgs/plus.svg';
import { ReactComponent as Podcast } from './svgs/podcast.svg';
import { ReactComponent as PrAssistant } from './svgs/pr-assistant.svg';
import { ReactComponent as Publish } from './svgs/publish.svg';
import { ReactComponent as Radio } from './svgs/radio.svg';
import { ReactComponent as Reach } from './svgs/reach.svg';
import { ReactComponent as Refresh } from './svgs/refresh.svg';
import { ReactComponent as Replace } from './svgs/replace.svg';
import { ReactComponent as Report } from './svgs/report.svg';
import { ReactComponent as Rss } from './svgs/rss.svg';
import { ReactComponent as Search } from './svgs/search.svg';
import { ReactComponent as Section } from './svgs/section.svg';
import { ReactComponent as SentimentNegative } from './svgs/sentiment-negative.svg';
import { ReactComponent as SentimentNeutral } from './svgs/sentiment-neutral.svg';
import { ReactComponent as SentimentPositive } from './svgs/sentiment-positive.svg';
import { ReactComponent as Settings } from './svgs/settings.svg';
import { ReactComponent as ShareOfVoice } from './svgs/share-of-voice.svg';
import { ReactComponent as Share } from './svgs/share.svg';
import { ReactComponent as SidebarOpened } from './svgs/sidebar-open.svg';
import { ReactComponent as SidebarClosed } from './svgs/sidebar.svg';
import { ReactComponent as Similarities } from './svgs/similarities.svg';
import { ReactComponent as Sliders } from './svgs/sliders.svg';
import { ReactComponent as Facebook } from './svgs/social-facebook.svg';
import { ReactComponent as Instagram } from './svgs/social-instagram.svg';
import { ReactComponent as LinkedIn } from './svgs/social-linkedin.svg';
import { ReactComponent as Mnd } from './svgs/social-mnd.svg';
import { ReactComponent as X } from './svgs/social-x.svg';
import { ReactComponent as Youtube } from './svgs/social-youtube.svg';
import { ReactComponent as Sparkle } from './svgs/sparkle.svg';
import { ReactComponent as StarFilled } from './svgs/star-filled.svg';
import { ReactComponent as Star } from './svgs/star.svg';
import { ReactComponent as Story } from './svgs/story.svg';
import { ReactComponent as Sun } from './svgs/sun.svg';
import { ReactComponent as TextFile } from './svgs/text-file.svg';
import { ReactComponent as ThumbsDownFilled } from './svgs/thumbs-down-filled.svg';
import { ReactComponent as ThumbsDown } from './svgs/thumbs-down.svg';
import { ReactComponent as ThumbsUpFilled } from './svgs/thumbs-up-filled.svg';
import { ReactComponent as ThumbsUp } from './svgs/thumbs-up.svg';
import { ReactComponent as Time } from './svgs/time.svg';
import { ReactComponent as Translate } from './svgs/translate.svg';
import { ReactComponent as TriangleWarning } from './svgs/triangle-warning.svg';
import { ReactComponent as TV } from './svgs/tv.svg';
import { ReactComponent as TvWeb } from './svgs/tv_web.svg';
import { ReactComponent as Upload } from './svgs/upload.svg';
import { ReactComponent as User } from './svgs/user.svg';
import { ReactComponent as VisibilityOff } from './svgs/visibility-off.svg';
import { ReactComponent as Visibility } from './svgs/visibility.svg';
import { ReactComponent as Web } from './svgs/web.svg';
import { ReactComponent as Written } from './svgs/written.svg';

import { ExtendedIconColors } from './SvgIconWrapper';
import { Size } from './types';

const wrapIcon = (Component: FC) => styled(SvgIconWrapper).attrs({ children: <Component /> })``;

const IconMatch0 = wrapIcon(Match0);
const IconMatch1 = wrapIcon(Match1);
const IconMatch2 = wrapIcon(Match2);
const IconMatch3 = wrapIcon(Match3);

/*
  Put this in a separate components
*/
const Match = ({
  value,
  breakpoints,
  $size,
  $color,
}: {
  value?: number;
  breakpoints?: { low: number; mid: number; high: number };
  $size?: Size;
  $color?: ExtendedIconColors;
}) => {
  if (value === undefined || breakpoints === undefined || value < breakpoints.low)
    return <IconMatch0 $size={$size} $color={$color} />;
  if (value >= breakpoints.low && value < breakpoints.mid)
    return <IconMatch1 $size={$size} $color={$color} />;
  if (value >= breakpoints.mid && value <= breakpoints.high)
    return <IconMatch2 $size={$size} $color={$color} />;
  if (value > breakpoints.high) return <IconMatch3 $size={$size} $color={$color} />;
  return <IconMatch0 $size={$size} $color={$color} />;
};

const eyesAnimation = keyframes`
  0% {
    transform: scale(1,1);
    animation-timing-function: cubic-bezier(0.645,0.045,0.355,1);
  }
  2% {
    transform: scale(1,0.04207);
    animation-timing-function: cubic-bezier(0.645,0.045,0.355,1);
  }
  4% {
    transform: scale(1,1);
  }
  34% {
    transform: scale(1,1);
  }
  36% {
    transform: scale(1,0.04207);
    animation-timing-function: cubic-bezier(0.645,0.045,0.355,1);
  }
  38% {
    transform: scale(1,1);
  }
  59% {
    transform: scale(1,1);
  }
  61% {
    transform: scale(1,0.04207);
    animation-timing-function: cubic-bezier(0.645,0.045,0.355,1);
  }
  62% {
    transform: scale(1,1)
  }
  63% {
    transform: scale(1,0.04207);
    animation-timing-function: cubic-bezier(0.645,0.045,0.355,1);
  }
  65% {
    transform: scale(1,1);
  }
  100% {
    transform: scale(1,1);
  }
`;

const WrappedPrAssistant = styled(SvgIconWrapper).attrs({
  children: <PrAssistant className={queries['pr-assistant']} />,
})`
  container-type: inline-size;
  [data-pr-assistant-eyes='true'] {
    transform-origin: center 47%;
    animation: ${eyesAnimation} 10000ms linear infinite normal;
  }
`;

const Icons = {
  Boolean: wrapIcon(Boolean),
  Calendar: wrapIcon(Calendar),
  ChevronLeft: wrapIcon(ChevronLeft),
  ChevronDown: wrapIcon(ChevronDown),
  ChevronUp: wrapIcon(ChevronUp),
  ChevronRight: wrapIcon(ChevronRight),
  Check: wrapIcon(Check),
  CircleClock: wrapIcon(CircleClock),
  Search: wrapIcon(Search),
  ExternalLink: wrapIcon(ExternalLink),
  MoreHorizontal: wrapIcon(MoreHorizontal),
  MoreVertical: wrapIcon(MoreVertical),
  Menu: wrapIcon(Menu),
  Close: wrapIcon(Close),
  Minus: wrapIcon(Minus),
  Contact: wrapIcon(Contact),
  ContactCheck: wrapIcon(ContactCheck),
  CreditCard: wrapIcon(CreditCard),
  Crown: wrapIcon(Crown),
  CrownFancy: wrapIcon(CrowFancy),
  Notification: wrapIcon(Notification),
  Settings: wrapIcon(Settings),
  Sliders: wrapIcon(Sliders),
  DefaultThumbnail: wrapIcon(DefaultThumbnail),
  DeleteSchedule: wrapIcon(DeleteSchedule),
  Comment: wrapIcon(Comment),
  Computer: wrapIcon(Computer),
  Download: wrapIcon(Download),
  Pin: wrapIcon(Pin),
  PinFilled: wrapIcon(PinFilled),
  VisibilityOff: wrapIcon(VisibilityOff),
  Visibility: wrapIcon(Visibility),
  Lighthouse: wrapIcon(Lighthouse),
  User: wrapIcon(User),
  Delete: wrapIcon(Delete),
  EmailSchedule: wrapIcon(EmailSchedule),
  Email: wrapIcon(Email),
  Story: wrapIcon(Story),
  AddToList: wrapIcon(AddToList),
  CircleSlash: wrapIcon(CircleSlash),
  Web: wrapIcon(Web),
  Share: wrapIcon(Share),
  Rss: wrapIcon(Rss),
  CircleQuestion: wrapIcon(CircleQuestion),
  Diamond: wrapIcon(Diamond),
  Benchmark: wrapIcon(Benchmark),
  AnalyzeAlt: wrapIcon(AnalyzeAlt),
  CircleUser: wrapIcon(CircleUser),
  CirclePlus: wrapIcon(CirclePlus),
  CircleInfo: wrapIcon(CircleInfo),
  AdminShield: wrapIcon(AdminShield),
  Analyze: wrapIcon(Analyze),
  Astromech: wrapIcon(Astromech),
  Heart: wrapIcon(Heart),
  HeartFilled: wrapIcon(HeartFilled),
  CircleCheck: wrapIcon(CircleCheck),
  Collapse: wrapIcon(Collapse),
  Expand: wrapIcon(Expand),
  CircleCross: wrapIcon(CircleCross),
  EasterEgg: wrapIcon(EasterEgg),
  PasswordUnlock: wrapIcon(PasswordUnlock),
  Pencil: wrapIcon(Pencil),
  Code: wrapIcon(Code),
  CircleError: wrapIcon(CircleError),
  TriangleWarning: wrapIcon(TriangleWarning),
  CircleInfoFilled: wrapIcon(CircleInfoFilled),
  Edit: wrapIcon(Edit),
  Written: wrapIcon(Written),
  ThumbsUpFilled: wrapIcon(ThumbsUpFilled),
  ThumbsUp: wrapIcon(ThumbsUp),
  ThumbsDownFilled: wrapIcon(ThumbsDownFilled),
  ThumbsDown: wrapIcon(ThumbsDown),
  LearningVideo: wrapIcon(LearningVideo),
  Match,
  IconMatch0,
  IconMatch1,
  IconMatch2,
  IconMatch3,
  SidebarOpened: wrapIcon(SidebarOpened),
  SidebarClosed: wrapIcon(SidebarClosed),
  Section: wrapIcon(Section),
  Replace: wrapIcon(Replace),
  Reach: wrapIcon(Reach),
  Chat: wrapIcon(Chat),
  TextFile: wrapIcon(TextFile),
  Upload: wrapIcon(Upload),
  ArrowDown: wrapIcon(ArrowDown),
  ArrowUp: wrapIcon(ArrowUp),
  ArrowRight: wrapIcon(ArrowRight),
  Facebook: wrapIcon(Facebook),
  Instagram: wrapIcon(Instagram),
  LinkedIn: wrapIcon(LinkedIn),
  Mnd: wrapIcon(Mnd),
  Youtube: wrapIcon(Youtube),
  X: wrapIcon(X),
  // not in yet figma
  Plus: wrapIcon(Plus),
  Moon: wrapIcon(Moon),
  Play: wrapIcon(Play),
  Sun: wrapIcon(Sun),
  Image: wrapIcon(Image),
  Pen: wrapIcon(Pen),
  Phone: wrapIcon(Phone),
  List: wrapIcon(List),
  Link: wrapIcon(Link),
  LockLocked: wrapIcon(LockLocked),
  LockUnlocked: wrapIcon(LockUnlocked),
  Paperclip: wrapIcon(Paperclip),
  Time: wrapIcon(Time),
  Refresh: wrapIcon(Refresh),
  Star: wrapIcon(Star),
  StarFilled: wrapIcon(StarFilled),
  Debug: wrapIcon(Debug),
  Sparkle: wrapIcon(Sparkle),
  PrAssistant: WrappedPrAssistant,
  Dashboard: wrapIcon(Dashboard),
  Contacts: wrapIcon(Contacts),
  Publish: wrapIcon(Publish),
  Monitor: wrapIcon(Monitor),
  Report: wrapIcon(Report),
  AnalyzeMenu: wrapIcon(AnalyzeMenu),
  SentimentPositive: wrapIcon(SentimentPositive),
  SentimentNegative: wrapIcon(SentimentNegative),
  SentimentNeutral: wrapIcon(SentimentNeutral),
  Differences: wrapIcon(Differences),
  Similarities: wrapIcon(Similarities),
  ShareOfVoice: wrapIcon(ShareOfVoice),
  ArrowDownRight: wrapIcon(ArrowDownRight),
  ArrowUpRight: wrapIcon(ArrowUpRight),
  Lightbulb: wrapIcon(Lightbulb),
  Globe: wrapIcon(Globe),
  DollarSign: wrapIcon(DollarSign),
  MndFilled: wrapIcon(MndFilled),
  Translate: wrapIcon(Translate),
  Radio: wrapIcon(Radio),
  TV: wrapIcon(TV),
  Podcast: wrapIcon(Podcast),
  TvWeb: wrapIcon(TvWeb),
};

export default Icons;
