import styled from 'styled-components';
import { SIZING_6, SPACING_1, SPACING_2 } from '../../theme';
import { Chip } from '../Chip';
import Icons from '../Icons';
import { InputWrapper } from '../Input';

const SelectedChipsWrapper = styled.div`
  cursor: pointer;
  display: flex;
  gap: ${SPACING_1};
  flex-wrap: wrap;
  padding: ${SPACING_2} ${SIZING_6} ${SPACING_2} ${SPACING_2};
  align-items: flex-start;
`;

const CustomButton = styled.button`
  background: none;
  border: 0;
  color: ${({ theme }) => theme.colorTextInputLabelEmptyEnabledDefault};
  flex-shrink: 0;
  font-weight: 300;
  height: ${({ theme }) => theme.sizingButtonHeight};
  line-height: 1.25rem;
  padding: 0;
  text-align: left;
  white-space: nowrap;
  &:hover {
    color: ${({ theme }) => theme.colorTextButtonTextTextButtonOnLightHover};
  }
`;

const Input = styled.input`
  border: none;
  background: transparent;
  flex: 1;
  height: ${({ theme }) => theme.sizingButtonHeight};
  outline: none;
  padding: 0;
  text-overflow: ellipsis;
  &::placeholder {
    color: ${({ theme }) => theme.colorTextInputLabelEmptyEnabledDefault};
    font-weight: 300;
  }
  &:focus,
  &:active {
    &::placeholder {
      color: transparent;
    }
  }
`;

type Chip = {
  value: string;
  label: string;
};

type Base<T extends Chip> = {
  label: string;
  onClick?: () => void;
  selectedItems?: T[];
  onRemove?: (id: string) => void;
  dataTestId?: string;
};

type Input = Base<Chip> & {
  type: 'search';
  searchQuery: string;
  onSearchChange: (query: string) => void;
};

type Modal = Base<Chip> & {
  type: 'modal';
};

type DropdownList = Base<Chip> & {
  type: 'dropdownList';
  isOpen: boolean;
};

type Props = Modal | Input | DropdownList;

export const InputChip = ({
  label,
  onClick,
  selectedItems = [],
  onRemove,
  dataTestId,
  type,
  ...props
}: Props) => {
  const trailingIcon =
    type === 'dropdownList' && 'isOpen' in props ? (
      props.isOpen ? (
        <Icons.ChevronUp />
      ) : (
        <Icons.ChevronDown />
      )
    ) : (
      <Icons.Search />
    );

  return (
    <InputWrapper hasContent={!!selectedItems} trailingIcon={trailingIcon} skipMargin>
      <SelectedChipsWrapper onClick={() => onClick?.()}>
        {selectedItems?.map(item => (
          <Chip
            key={item.value}
            type="selection"
            onRemove={e => {
              e?.stopPropagation();
              onRemove?.(item.value);
            }}
          >
            {item.label}
          </Chip>
        ))}
        {type === 'search' && (
          <Input
            type="text"
            placeholder={label}
            value={'searchQuery' in props ? props.searchQuery : ''}
            onChange={e =>
              'onSearchChange' in props ? props.onSearchChange(e.target.value) : null
            }
            onClick={onClick}
          />
        )}
        {(type === 'modal' || type === 'dropdownList') && (
          <CustomButton type="button" onClick={onClick} data-testid={dataTestId}>
            {label}
          </CustomButton>
        )}
      </SelectedChipsWrapper>
    </InputWrapper>
  );
};
